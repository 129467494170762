import styled from "styled-components";
import { Bars } from "react-loader-spinner";
import React from "react";
const Spinner = () => {
  return (
    <Spin>
      <Bars
        height="80"
        width="80"
        color="#3e1b15"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bar"
      />
    </Spin>
  )
}
const Spin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default Spinner