/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { VideoReview, VideoReview2, VideoReview3, VideoReview4 } from '../theme/images';


export const CarousalImage = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex:any, e:any) => {
        setIndex(selectedIndex);
      };
  return (
    <div>
        <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
        <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src={VideoReview} height={'300px'} width={'400px'}></iframe>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe className="embed-responsive-item" src={VideoReview2} height={'300px'} width={'400px'}></iframe>
        </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe className="embed-responsive-item" src={VideoReview3} height={'300px'} width={'400px'}></iframe>
        </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe className="embed-responsive-item" src={VideoReview4} height={'300px'} width={'400px'}></iframe>
        </div>
        </Carousel.Item>
    </Carousel>
    </div>
  )
}
