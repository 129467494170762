import React from 'react'
import Slider from 'react-touch-drag-slider'

import { R1099,R2099, R3099, R4099 } from '../theme/images';


const images99 = [R1099, R2099, R3099, R4099] ;


const SliderComponents = () => {

  return (
    <Slider
          onSlideComplete={(i) => {
            console.log('finished dragging, current slide is', i)
          }}
          onSlideStart={(i) => {
            console.log('started dragging on slide', i)
          }}
          activeIndex={0}
          threshHold={100}
          transition={0.5}
          scaleOnDrag={true}
        >
            {images99.map((value, index) => (<img src={value} key={index} alt={''} />)
            )}
    </Slider>
  )
}

export default SliderComponents