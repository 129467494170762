import {useState, useEffect } from "react";
import React from "react";
import styled from 'styled-components';

import Spinner from "../elements/Spinner";


import {R1099, R2099, R3099, R4099,
  R30149, R40149, R50149, R60149,
  R10169, R20169,
  R10179, R30179, R50179, R70179, R80179, R100179, R110179, R180179,
  R120179, R130179, R140179, R150179, R160179, R170179,
  R40199, R50199, R60199, R70199, R80199, R100199, R110199, R1200199, R1300199,
  R1400199, R1500199, R1600199, R1800199, R1900199, R2000199,
  R10239, R20239,R30239, R40239, R80239, R110239, R1200239,  R1300239,R1400239,
  R10249, R20249, R30249, R40249, R50249,R60249, R70249, R80249,
  R90289,R100289,
  R10299,
      } from '../theme/images';
import { CarousalImage } from "../elements";
import { Modal } from "react-bootstrap";

const imgs = new Map()
imgs.set('99', [R1099, R2099, R3099, R4099] )
imgs.set('149', [R30149, R40149, R50149,R60149])
imgs.set('169', [R10169, R20169])
imgs.set('179', [R10179, R170179, R30179, R70179, R80179, R100179, R110179, R120179, R130179, R140179, R150179, R160179, R50179, R180179])
imgs.set('199', [R40199, R50199, R70199, R80199, R100199, R110199, R1200199, R1300199, R60199, R1400199, 
                R1500199, R1600199, R1800199, R1900199, R2000199])
imgs.set('239', [R10239, R20239,R30239, R40239, R80239  , R110239, R1200239, R1300239, R1400239])
imgs.set('249', [R60249, R70249, R80249])
imgs.set('289', [R90289,R100289])
imgs.set('299', [R10299, R10249, R20249, R30249, R40249, R50249])

      

const MainPage = () =>{
    const [imageToShow, setImageToShow] = useState("");
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    
    const imagesFinal:JSX.Element[] = [];
    imgs.forEach((imgArray, price) => {
      imagesFinal.push(
        <div className="middle-section">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='span-only99'>
              <div className="price">
                <span>₹ {price}</span>
              </div>
              <div className=''>
              {imgArray.map((img:string)=>
                (
                <>
                <img className="image-card" 
                onClick=
                {() => showImage(img)} 
                src={img} 
                alt="imag-card" 
                />
                </>
                ))
              }
              </div>
            </div>
          </div>
        </div>
        </div>

      )
    }

    );
  
    //function to show a specific image in the lightbox, amd make lightbox visible
    const showImage = (image:any) => {
      setImageToShow(image);
      setLightBoxDisplay(true);
    };
  
    //hide lightbox
    const hideLightBox = () => {
      setLightBoxDisplay(false);
    };
  
    //show next image in lightbox
    const showNext = (e:any) => {
      e.stopPropagation();
      console.log(e, "event")
      const images199 = imgs.get('199')
      let currentIndex = images199.indexOf(imageToShow);
      if (currentIndex >= images199.length - 1) {
        setLightBoxDisplay(false);
      } else {
        let nextImage = images199[currentIndex + 1];
        setImageToShow(nextImage);
      }
    };
  
    //show previous image in lightbox
    const showPrev = (e:any) => {
      e.stopPropagation();
      const images199 = imgs.get('199')
      let currentIndex = images199.indexOf(imageToShow);
      if (currentIndex <= 0) {
        setLightBoxDisplay(false);
      } else {
        let nextImage = images199[currentIndex - 1];
        setImageToShow(nextImage);
      }
    };

    useEffect(()=>{
      setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      }, [])
  
  return (
    <MiddleSection>
      <div className="middle-top">
      <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
          <div className="caraoual">
            <h6 className="animate-charcter">' Reviews '</h6>
          <CarousalImage/>
          </div>
        </div>
        <div className="col-lg-4"></div>
      </div>
      </div>

      {isLoading?<Spinner/>:
      <div className='container-fluid'>
        {imagesFinal}
        <a href=
        "https://www.instagram.com/just_in_rings/" className="float" >
        <i className="fa fa-instagram  my-float"></i>
        </a>
      </div>
    }
        {lightboxDisplay ? (
          <div id="lightbox" onClick={hideLightBox}>
            <button onClick={showPrev}>⭠</button>
            <img id="lightbox-img" src={imageToShow} alt="lightbox-img"></img>
            <button onClick={showNext}>⭢</button>
          </div>
        ) : (
          ""
        )}
        <button className="backtotop"
        onClick={()=>{window.scrollTo({ top: 0, behavior: "smooth" });
        }}>
          Back to top
        </button>
    </MiddleSection>
  )
}

const MiddleSection = styled.div`
  padding: 50px 50px;
  text-align: center;
  background-color: #ffffff;
  background-size : cover;
  .middle-top{
    margin-top: 50px;
  }
  .caraoual{
    padding-top: 50px;
    .animate-charcter
    {
      text-transform: uppercase;
      background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%
      );
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textclip 2s linear infinite;
      display: inline-block;
      /* font-size: 25px; */
    }

    @keyframes textclip {
      to {
        background-position: 200% center;
      }
    }

    h6{
      font-family: 'Dekko';
      font-size: 30px;
      background: #ddeedd;
      font-weight: bold;
    }
  }
  .carousel-inner{
  width:100%;
  /* max-height: 200px !important; */
  }
.middle-section{
  padding-top: 52px;
}
  .float{
	position:fixed;
	width:52px;
	height:55px;
	bottom:40px;
	right:5px;
	border-radius:18px;
	text-align:center;
  font-size:30px;
  z-index:100;
  background-color: #ffff;
}
  .fa.fa-instagram {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    background-color: red;
    
  }
  .my-float{
    margin-top:16px;
    height: 30px;
    width: 30px;
  }
  .image-card {
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin: 0px 6px 20px 1px;
  }

  #lightbox-img {
    height: 400px;
    max-width: 300px;
    /* object-fit: cover; */
  }

  #lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
   /* Completely optional styling that has nothing to do with the lightbox */
  button {
    color: white;
    border: 2px solid #a167da;
    background-color: #a167da;
    font-size: 1.2rem;
    
  }
  .backtotop{
  color: #000000;
    background-color: #edebf0;
    font-weight: bolder;
    border: none;
    padding: 13px 27px;
    background-color: #efecf2;
    margin-bottom: 10px;
    border-radius: 5%;
  }

   a {
     color: #7a4baa;
   }

   h1 {
     color: #7a4baa;
   }

   p {
     margin-bottom: 1.5rem;
     font-size: .9rem;
   }

   img:hover, button:hover {
     cursor: pointer;
   }
    .span-only99{
      /* padding-top: 103px; */
    }
    .price{
      span{
      text-align:left;
      padding: 10px 10px;
      background-color: #919191;
      color: rgb(255, 255, 255);
      font-weight: bolder;
      }
    }
  @media (max-width: 576px) {
    .image-card{
      /* width: 113px;
      height: 154px; */
      margin: 20px 7px 3px 4px;
    }
  }
  @media (max-width: 376px) {
    .image-card{
      /* width: 109px; */
      width:  auto;
      /* height: 109px; */
      /* margin: 20px 7px 28px 4px; */
    }
  }
  @media (min-width: 992px) {
    .image-card{
      /* width: 30vw; */
    height: 40vw;
    object-fit: cover;
    padding: 25px 26px 17px 1px;
      /* border-radius: 9%; */
    margin: 20px 20px 20px 0px;
    }
  }
  @media (min-width: 521px) {
    .image-card{
    width: 184px;
    height: 200px;
    margin: 20px 7px 28px 4px;
    }
  }
  @media (min-width: 528px) {
    .image-card{
    width: 188px;
    height: 200px;
    margin: 20px 7px 28px 4px;
    }
  }
  @media (min-width: 431px) {
    .image-card{
    width: 139px;
    height: 181px;
    margin-top: 21px;
    /* margin: 20px 7px 4px 4px; */
    }
  }
  @media (min-width: 498px) {
    .image-card{
    /* width: 183px; */
    height: 276px;
    width: 100%;
    /* margin: 20px 7px 4px 4px; */
    }
  }
  @media (min-width: 530px) {
    .image-card{
      width: 324px;
      height: auto;
    }
  }
  @media (min-width: 400px) {
  #lightbox-img {
  height: 400px;
  max-width: 300px;
  width: 100%;
    }
  }
  @media (min-width: 313px) {
  #lightbox-img {
  height: 400px;
  max-width: 300px;
  width: 100%;
  }
  }
 `;


export default MainPage;