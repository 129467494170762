import React from 'react'
import Marquee from 'react-fast-marquee';

import { Insta } from '../theme/images';
import styled from 'styled-components';
const HeaderComponent = () => {
  return(<>
    <HeadingTop>
      <Marquee>
        <span>Shop now and get 10% OFF on shopping above ₹1000 &nbsp;&nbsp;&nbsp;</span>
    </Marquee>
  </HeadingTop>
  <HeaderStyling>
    <div className='insta'>
      <span>💝&nbsp;Loved our collection !&nbsp;💝 </span>
      <span>Follow us or DM on</span>
      <a href='https://www.instagram.com/just_in_rings/?next=%2F'>
        <img
        src={Insta} alt='gif' 
        className="instagram-gif"
        />
        </a>
        </div>
      </HeaderStyling>
      </>
    )
}
const HeadingTop = styled.div`
  background-color: #6f0e1e;
  color: #fff;
  width:100%;
  p{
    background-color: #dcd6d6;
    color: #000000;
    text-align: center;
    font-size: 15px;
    padding: 0px;
    margin: 0;
  }
`;
const HeaderStyling = styled.div`
  width: 100%;
  background-color:#f1f2f2;
  text-align: center;
  .insta{
      font-weight: bolder;
      vertical-align: center;
      span{
        text-align:center;
      }
    .instagram-gif{
      width:100px;
      height: 100px;
      margin-left: -14px;
    }
}
  img{
    height: 90px;
    width: 91px;
    padding: 11px;
  }`;

export default HeaderComponent;