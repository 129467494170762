import React, { useEffect, useState } from 'react';
import './App.css';


import Spinner from './elements/Spinner';
import { HeaderComponent, MainPage, Footer } from './components';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
  }, [])

  return (
    <>
      {isLoading ? <Spinner /> :
        <>
          <HeaderComponent />
          <MainPage />
          <Footer />
        </>
      }
    </>
  );
}

export default App;
