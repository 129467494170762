import React from 'react';
import { Insta, Logo } from '../theme/images';
import styled from 'styled-components';
import Marquee from 'react-fast-marquee';


const HeaderComponent = () => {
    return(
      <>
      <HeaderStyling>
        <img src={Logo} alt="Logo"/>
        <h2>Just In Rings</h2>
        <div className='insta'>
          <span>Follow us on !</span>
          <a href='https://www.instagram.com/just_in_rings/?next=%2F'><img src={Insta} alt='gif' className="instagram-gif"/></a>
        </div>
      </HeaderStyling>
      <HeadingTop>
      <Marquee>
        <span>Shop now and get 10% OFF on shopping above ₹1000 &nbsp;&nbsp;&nbsp;</span>
      </Marquee>
        <p> 💝&nbsp;Loved Our Collection DM us On Instagram!&nbsp;💝 </p>
      </HeadingTop>
      </>  
    )
}
const HeadingTop = styled.div`
  background-color: #6f0e1e;
  color: #fff;
  width:100%;
  position: fixed;
  z-index: 100;
  p{
    background-color: #dcd6d6;
    color: #000000;
    text-align: center;
    font-size: 15px;
    padding: 0px;
    margin: 0;
  }
`;

const HeaderStyling = styled.div`
  position:fixed;
  width: 100%;
  background-color:#f1f2f2;
  z-index: 100;
  margin-top: 43px;
  .insta{
    position: absolute;
      right: 10px;
      top:5px;
      font-weight: bolder;
    .instagram-gif{
      width:100px;
      height: 100px;
      margin-left: -14px;
    }
}
  .marquee{
    font-size: 32px;
    color:#795548;
    font-weight:bold;
  }
  img{
    height: 90px;
    width: 91px;
    padding: 11px;
  }
  h2{
    display: inline-block;
    font-family: 'Kalam', cursive;
    font-family: 'Source Code Pro', monospace; 
    /* vertical-align: text-bottom; */
    font-weight: bolder;
  }
  @media (min-width: 500px) {
      .insta{
        .instagram-gif{
          width:140px;
          height: 115px;
          margin-left: -14px;
          position: absolute;
          top: -3px;
          left: -22px;
        }
        span{
          display: block;
        }
      }
      h2{
        font-size: 25px;
      }
    }
  @media (min-width: 400px) {
    .insta{
      .instagram-gif{
        width:140px;
        height: 115px;
        margin-left: -14px;
        position: absolute;
        top: -3px;
        left: -22px;
      }
      span{
        display: block;
      }
    }
    h2{
      font-size: 25px;
    }
  }
  @media (man-width: 340px) {
    .insta{
      position: absolute;
      right: 5px;
    top: 13px;
    font-weight: bolder;
      .instagram-gif{
        width:119px;
        height: 106px;
        margin-left: -14px;
        position: absolute;
        top: -3px;
        left: 1px;
      }
      span{
        display: block;
      }
    }
    h2{
      font-size: 18px;
    }
  }
  @media (max-width: 700px) {
    .insta{
      position: absolute;
      right: 5px;
    top: 13px;
    font-weight: bolder;
      .instagram-gif{
        width:119px;
        height: 106px;
        margin-left: -14px;
        position: absolute;
        top: -3px;
        left: 1px;
      }
      span{
        display: block;
      }
    }
    h2{
      font-size: 18px;
    }
  }
`;

export default HeaderComponent